import "./Portfolio.scss";
import { useEffect, useRef, useState } from "react";

//We don't care about the typing for figlet.
// @ts-ignore
import figlet from "figlet";
// @ts-ignore
import Slant from "figlet/importable-fonts/Slant.js";
// @ts-ignore
import Small from "figlet/importable-fonts/Small.js";

import { TypeAnimation } from "react-type-animation";
import useScreenSize from "./useScreenSize";

figlet.parseFont("Slant", Slant);
figlet.parseFont("Small", Small);

type TypingLineProps = {
  text: string;
  onFinished: () => any;
  delay?: number;
};

function TypingLine(p: TypingLineProps) {
  // How long will it take this to play?
  const timeToAnimate = p.text.length * 100 + 5;
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    const s = setTimeout(() => {
      setFinished(true);
      p.onFinished();
    }, timeToAnimate + (p.delay || 10));

    return () => {
      clearTimeout(s);
    };
  });

  return (
    <div>
      <span className="green">{"\u279C"}</span>
      <span className="purple">&nbsp;~&nbsp;</span>
      <span className={finished ? "green" : ""}>
        <TypeAnimation
          sequence={[5, p.text]}
          cursor={false}
          speed={{ type: "keyStrokeDelayInMs", value: 100 }}
        />
      </span>
      <span className={"cursor" + (finished ? " hidden" : "")}>|</span>
    </div>
  );
}

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

function RandomColorScheme() {
  let choices = [
    ["pink", "cyan"],
    ["purple", "cyan"],
    ["cyan", "pink"],
    ["cyan", "purple"],
    ["purple", "pink"],
    ["pink", "purple"],
  ];

  return choices[getRandomInt(6)];
}

const ColorScheme = ["cyan", "pink"];

const Resume = (props: { changePage: (p: Pages) => void }) => {
  const [isUnderlined, setIsUnderlined] = useState(false);
  const screenSize = useScreenSize();

  return (
    <div className="container">
      <div
        style={{ justifyContent: screenSize.width < 619 ? "center" : "start" }}
        className={`header ${ColorScheme[0]}`}
      >
        {figlet.textSync("Resume", {
          font: screenSize.width < 619 ? "Small" : "Slant",
          showHardBlanks: false,
        })}
      </div>
      <div>
        <span
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            props.changePage(Pages.home);
          }}
          onMouseOver={() => {
            setIsUnderlined(true);
          }}
          onMouseLeave={() => {
            setIsUnderlined(false);
          }}
          className={isUnderlined ? "underline" : ""}
        >
          {"< Go Back"}
        </span>
      </div>
      <div className="tbox">
        <div className="legend-holder">
          <div className="legend">Education</div>
        </div>
        <br />
        <div className={`split ${ColorScheme[1]}`}>
          <span>Bachelors of Science in Computer Science</span>
          <span>May 2025</span>
        </div>
        <div>Texas Tech University</div>
        <div>Whitacre College of Engineering</div>
        <br></br>
      </div>
      <div className="tbox">
        <div className="legend-holder">
          <div className="legend">Professional Experience</div>
        </div>
        <br />
        <div className={`split ${ColorScheme[1]}`}>
          <span>Software Developer</span>
          <span>August 2024 - Current</span>
        </div>
        <div className="bold">
          Tyler Technologies - Municipal & Schools Division
        </div>
        <br />
        <div>
          - Develop full-stack cloud application using ASP.NET and Angular with
          a MS SQL Server database to support numerous municipalities.
        </div>
        <br />
        <div>
          - Work with a large team of developers in an Agile environment,
          participating in daily standups and biweekly sprint planning meetings
          and retrospectives.
        </div>
        <br />
        <div>
          - Collaborate with Product and QA using Jira for task management,
          ensuring smooth handoffs, efficent testing, and timely biweekly
          releases.
        </div>
        <br />
        <div className={`split ${ColorScheme[1]}`}>
          <span>Software Engineering Intern</span>
          <span>May 2024 - August 2024</span>
        </div>
        <div className="bold">
          Tyler Technologies - Municipal & Schools Division
        </div>
        <br />
        <div>
          - Successfully completed summer internship program, was offered a
          part-time position to continue with the company after internship
          program ended.
        </div>
        <br />
        <div>
          - Attended various professional development seminars as part of the
          internship program, gaining deeper knowledge of CI/CD pipelines, cloud
          computing, and containerization.
        </div>
        <br />
        <div>
          - Contributed 46.5 story points, surpassing the assigned 33.5 points
          for the internship.
        </div>
        <br />
        <div className={`split ${ColorScheme[1]}`}>
          <span>Undergraduate Researcher</span>
          <span>January 2024 - May 2024</span>
        </div>
        <div className="bold">
          Texas Tech University - Department of Computer Science
        </div>
        <br />
        <div>
          - Worked with principal investigator and team of students to research
          a software testbed for unmanned aerial vehicles for a Department of
          Homeland Security sponsored project.
        </div>
        <br />
        <div>
          - Created and presented several demonstration programs to simulate
          multi-drone wireless networking using Omnet++ and C++.
        </div>
        <br />
        <div>
          - Wrote a detailed technical report at the conclusion of the program
          to summarize findings and recommend a final language and framework for
          the research testbed.
        </div>
        <br />
        <div className={`split ${ColorScheme[1]}`}>
          <span>Owner and Lead Developer</span>
          <span>July 2022 - Current</span>
        </div>
        <div className="bold">Texan Simulations</div>
        <br />
        <div>
          - Founded Texan Simulations, which focuses on creating and selling
          high-fidelity recreations of real-life airports for Microsoft Flight
          Simulator 2020.
        </div>
        <br />
        <div>
          - Successfully co-developed and launched our first commercial product,
          a rendition of William P. Hobby Airport (KHOU) in May 2023, which has
          now sold over 1500 units.
        </div>
        <br />
        <div>
          - Managed the end-to-end development process for the scenery to ensure
          on-time release.
        </div>
        <br />
        <div>
          - Developed custom software tools to automate complex and tedious
          tasks, which significantly reduced development time and increased
          efficiency.
        </div>
        <br />
      </div>
      <div className="tbox">
        <div className="legend-holder">
          <div className="legend">
            Programming Languages, Tools & Frameworks
          </div>
        </div>
        <br />
        <div className={`${ColorScheme[1]} bold`}>Languages</div>
        <div>
          JavaScript, Node.js, C#, Python, C, C++, Java, Swift, HTML, CSS,
          MATLAB, SQL
        </div>
        <br />
        <div className={`${ColorScheme[1]} bold`}>Frameworks</div>
        <div>
          .NET, ASP.NET, Angular, React, SwiftUI, JavaFX, Moq, ExpressJS,
          Firebase, React Native
        </div>
        <br />
        <div className={`${ColorScheme[1]} bold`}>Databases</div>
        <div>MS SQL Server, MySQL, MongoDB, Redis, SQLite and Realm</div>
        <br />
        <div className={`${ColorScheme[1]} bold`}>Tools</div>
        <div>
          NPM, Unix, PowerShell, Docker, Git, Github, Jira & Confluence,
          Swagger, Figma
        </div>
        <br />
      </div>
      <div className="tbox">
        <div className="legend-holder">
          <div className="legend">Skills</div>
        </div>
        <br />
        <div className={`${ColorScheme[1]} bold`}>Technical Skills</div>
        <div>
          Full Stack Application Development, Web Development, iOS Development,
          REST API Development, Software Systems, Software Design &
          Documentation, Algorithm Design, Application & Unit Testing, Database
          Management (Relational & NoSQL), Version Control, and CI/CD Pipeline
          Processes.
        </div>
        <br />
        <div className={`${ColorScheme[1]} bold`}>Professional Skills</div>
        <div>
          Agile Development & Sprint Planning, Technical Writing, Project
          Management, Organizational Management, Leadership & Team
          Collaboration, Software Demonstration, Software Support,
          Cybersecurity, and Information Technology.
        </div>
        <br />
      </div>
    </div>
  );
};

const Content = (props: {
  changePage: (p: Pages) => void;
  shouldShowAnimation: boolean;
}) => {
  const [selected, setSelected] = useState(0);
  const [showContent, setShowContent] = useState(!props.shouldShowAnimation);
  const screenSize = useScreenSize();
  let selectedRef = useRef(0);

  const changeMenuItem = (e: KeyboardEvent) => {
    switch (e.key) {
      case "ArrowDown":
        var currentRef = selectedRef.current;
        setSelected((prevSelected) => (prevSelected + 1) % 6);
        selectedRef.current = (currentRef + 1) % 6;
        e.preventDefault();
        break;
      case "ArrowUp":
        var currentRef = selectedRef.current;
        setSelected((prevSelected) => (prevSelected - 1 + 6) % 6);
        selectedRef.current = (currentRef - 1 + 6) % 6;
        e.preventDefault();
        break;
      case "Enter":
        onSelect();
        e.preventDefault();
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", changeMenuItem);
    return () => {
      window.removeEventListener("keydown", changeMenuItem);
    };
  }, [changeMenuItem]);

  function isSelected(on: number) {
    if (selected === on) {
      return `selected ${ColorScheme[1]}`;
    } else {
      return "";
    }
  }

  function getHeaderText() {
    if (screenSize.width > 862) {
      return "Jackson Casey";
    } else if (screenSize.width <= 862 && screenSize.width > 618) {
      return "Jackson C";
    } else {
      return "J.C.";
    }
  }

  function onSelect() {
    switch (selectedRef.current) {
      case 0:
        props.changePage(Pages.resume);
        break;
      case 1:
        window.open("https://alertgx.jaxcksn.dev/");
        break;
      case 2:
        window.open("https://github.com/jaxcksn/CompSciDegreePlan");
        break;
      case 3:
        window.open("https://github.com/jaxcksn/FSMD");
        break;
      case 4:
        window.open("https://github.com/jaxcksn");
        break;
      case 5:
        window.open("https://www.linkedin.com/in/jackson-casey/");
        break;
      default:
        return;
    }
  }

  return (
    <div className="container">
      <div
        style={screenSize.width < 619 ? { justifyContent: "center" } : {}}
        className={`header ${ColorScheme[0]}`}
      >
        {figlet.textSync(getHeaderText(), {
          font: "Slant",
          showHardBlanks: false,
        })}
      </div>
      {props.shouldShowAnimation && (
        <TypingLine
          text="./portfolio"
          onFinished={() => {
            setShowContent(true);
          }}
        ></TypingLine>
      )}
      {!props.shouldShowAnimation && (
        <div>
          <span className="green">{"\u279C"}</span>
          <span className="purple">&nbsp;~&nbsp;</span>
          <span className={"green"}>{"./portfolio"}</span>
          <span className={"cursor hidden"}>|</span>
        </div>
      )}
      <br />
      {showContent && (
        <>
          <div className="highlight">
            Portfolio v0.3.1 - Edited Sep 17, 2024
          </div>
          <br />
          <div className="tbox">
            <div className="legend-holder">
              <div className="legend">About Me</div>
            </div>
            <div className="inner">
              <div className="firstBreak"></div>
              <span>
                Howdy, my name is Jackson, I'm a senior studying Computer
                Science at Texas Tech University and a part-time developer at
                Tyler Technologies.
                <br></br>
                <br></br>
                Welcome to my Portfolio! You can check out some of my projects,
                and view my resume on here.
              </span>
              <br></br>
            </div>
          </div>
          <br />
          <div className="tbox">
            <div className="legend-holder">
              <div className="legend">My Projects</div>
            </div>
            <div className="inner" style={{ flexDirection: "column" }}>
              <div className="hint">
                Use the arrow keys + enter, or click on a item.
              </div>
              <br></br>
              <div>
                <span
                  className={isSelected(0)}
                  onClick={onSelect}
                  onMouseOver={() => {
                    setSelected(0);
                    selectedRef.current = 0;
                  }}
                >
                  <i className="fa-solid fa-file mr"></i>
                  My Resume
                </span>
              </div>
              <br></br>
              <div>
                <span
                  className={isSelected(1)}
                  onClick={onSelect}
                  onMouseOver={() => {
                    setSelected(1);
                    selectedRef.current = 1;
                  }}
                >
                  <i className="fa-solid fa-cloud mr"></i>
                  AlertGX
                </span>
              </div>
              <br></br>
              <div>
                <span
                  className={isSelected(2)}
                  onClick={onSelect}
                  onMouseOver={() => {
                    setSelected(2);
                    selectedRef.current = 2;
                  }}
                >
                  <i className="fa-solid fa-building-columns mr"></i>
                  CS Degree Plan
                </span>
              </div>
              <br></br>
              <div>
                <span
                  className={isSelected(3)}
                  onClick={onSelect}
                  onMouseOver={() => {
                    setSelected(3);
                    selectedRef.current = 3;
                  }}
                >
                  <i className="fa-solid fa-terminal mr"></i>
                  FSMD CLI Tool
                </span>
              </div>
              <br />
            </div>
          </div>
          <br />
          <div className="tbox">
            <div className="legend-holder">
              <div className="legend">My Profiles</div>
            </div>
            <div className="inner" style={{ flexDirection: "column" }}>
              <div className="hint">
                Use the arrow keys + enter, or click on a item.
              </div>
              <br></br>
              <div>
                <span
                  className={isSelected(4)}
                  onClick={onSelect}
                  onMouseOver={() => {
                    setSelected(4);
                    selectedRef.current = 4;
                  }}
                >
                  <i className="fa-brands fa-github mr"></i>
                  Github
                </span>
              </div>
              <br></br>
              <div>
                <span
                  className={isSelected(5)}
                  onClick={onSelect}
                  onMouseOver={() => {
                    setSelected(5);
                    selectedRef.current = 5;
                  }}
                >
                  <i className="fa-brands fa-linkedin mr"></i>
                  LinkedIn
                </span>
              </div>
              <br />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

type ParamKeys = {
  [key: string]: string | null;
};

enum Pages {
  home,
  resume,
}

//MARK - Main Component
function Portfolio() {
  const [didShowResume, setDidShowResume] = useState(false);
  const [currentPage, setCurrentPage] = useState(Pages.home);

  const getComponentForPage = (page: Pages) => {
    if (page == Pages.resume) {
      //TODO - Replace with the resume component.
      return (
        <Resume
          changePage={(page: Pages) => {
            setDidShowResume(true);
            setCurrentPage(page);
          }}
        />
      );
    } else {
      return (
        <Content
          changePage={(page: Pages) => {
            setDidShowResume(true);
            setCurrentPage(page);
          }}
          shouldShowAnimation={!didShowResume}
        />
      );
    }
  };

  return getComponentForPage(currentPage);
}

export default Portfolio;
